.container {
  padding: 20px;
}

.img-container {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
