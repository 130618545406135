@import '@popsure/dirty-swan/dist/grid';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 80px;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
  margin-top: 16px;
}

.signature-container {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
}

.submit {
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: 24px;

  @include p-size-desktop {
    min-width: 212px;
  }
}

.form {
  margin-top: 32px;
}

.label {
  margin-left: 4px;
}

.error {
  margin-top: 16px;
}
