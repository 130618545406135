.container {
  cursor: pointer;

  position: relative;

  display: block;

  width: 40px;
  height: 40px;
  background-color: white;
  background-image: url('./img/logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  border-radius: 8px;

  box-shadow: 0px 4px 8px rgba(210, 210, 216, 0.25);

  transition: width 0.3s;
  transition-timing-function: ease;

  &:hover {
    width: 110px;

    & > .powered-technology {
      display: block;
    }
  }
}

@keyframes appear-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.powered-technology {
  position: absolute;

  top: 8px;
  left: 35px;
  right: 12px;
  bottom: 8px;

  display: none;

  background-image: url('./img/powered-tech.svg');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;

  animation-name: appear-in-animation;
  animation-timing-function: both;
  animation-delay: 0.3s;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
