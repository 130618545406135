.emoji {
  font-size: 60px;
  text-align: center;
}

.title {
  margin-top: 32px;
  text-align: center;
}

.subtitle {
  margin-top: 16px;
  text-align: center;
}

.reasons {
  margin-top: 16px;
  text-align: center;
}

.button {
  display: inline-block !important;
  margin: 16px auto 0 auto;
  min-width: 240px;

  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
