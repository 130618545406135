@import '~@popsure/dirty-swan/dist/grid';

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  animation-name: fade-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

.steps {
  display: grid;
  grid-gap: 16px;

  @include p-size-tablet {
    grid-template-columns: repeat(3, 234px);
    justify-content: center;
  }

  &Reduced {
    @include p-size-tablet {
      grid-template-columns: repeat(1, 234px);
    }
  }
}

.button {
  margin-bottom: 40px;

  @include p-size-tablet {
    margin-bottom: 0;
    max-width: 288px;
  }
}
