@import '@popsure/dirty-swan/dist/colors';

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.container {
  display: flex;

  background-color: white;
  line-height: 14px;

  align-items: flex-start;

  padding: 12px;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  border-radius: 8px;
  box-shadow: 0px 2px 3px rgba(38, 38, 46, 0.04);
}

.info-icon {
  width: 20px;
  height: 20px;

  margin-right: 12px;
}
