.header {
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 72px;
  min-height: 72px;
}

.logo {
  width: 40px;
  height: 40px;

  &.tk {
    background: url('./img/tk-logo.svg');
    background-size: contain;
  }

  &.dak {
    background: url('./img/dak-logo.svg');
    background-size: contain;
  }

  &.bkk {
    width: 130px;
    background: url('./img/bkk-logo.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.aok {
    background: url('./img/aok-logo.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.barmer {
    width: 120px;
    background: url('./img/barmer-logo.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
