@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

@keyframes appearInAnimation {
  from {
    bottom: -48px;
  }
  to {
    bottom: 0;
  }
}

.footer {
  position: fixed;

  width: 100%;
  bottom: 0;
  height: 48px;

  background-color: white;
  box-shadow: 0px 0px 2px rgba(38, 38, 46, 0.12);

  animation-delay: 0.6s;
  animation-name: appearInAnimation;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  @include p-size-desktop {
    display: block;
    width: 896px;

    left: 50%;
    transform: translateX(-50%);

    border-radius: 8px 8px 0 0;
  }
}

.back {
  cursor: pointer;

  display: inline-block;

  width: 48px;
  height: 48px;

  mask-image: url('arrow-left.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: $ds-primary-500;

  &:hover {
    background-color: $ds-primary-700;
  }

  &[disabled] {
    cursor: default;
    background-color: $ds-grey-300;
  }
}

.progress-container {
  position: absolute;
  height: 4px;

  transform: translateY(-50%);
  top: 50%;
  left: 48px;
  right: 16px;

  border-radius: 2px;
  background-color: $ds-grey-200;

  overflow: hidden;
}

.progress {
  height: 100%;

  background-color: $ds-primary-500;
  border-radius: 2px;

  transition: width 0.3s;
}
