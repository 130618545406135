@import '@popsure/dirty-swan/dist/grid';

.subtitle {
  margin-top: 8px;
}

.title {
  @include p-size-tablet {
    max-width: 680px;
  }
}

.subtitle {
  @include p-size-tablet {
    max-width: 820px;
  }
}

.wrapper {
  @include p-size-tablet {
    display: flex;
  }
}

.question {
  flex: 1 1;

  @include p-size-tablet {
    margin-right: 32px;
  }
}

.content {
  flex: 1 1;
}
