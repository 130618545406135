html {
  height: 100%;
}

html,
body {
  overflow: auto;
}

body {
  min-height: 100%;
  display: flex;
  position: relative;
}

.public-signup-center-element {
  min-height: calc(100vh - 72px);
  display: flex;
  align-items: center;
}
