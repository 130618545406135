.emoji {
  font-size: 60px;
  text-align: center;
}

.title {
  margin-top: 32px;
  text-align: center;
}

.subtitle {
  margin-top: 16px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
