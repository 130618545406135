.image {
  display: block;
  margin: 0 auto;
}

.description {
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  font-size: 20px;
  text-align: center;
  max-width: 360px;
}

.homepage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  text-align: center;
}
