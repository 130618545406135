@import '~@popsure/dirty-swan/dist/grid';

.container {
  padding: 24px;

  @include p-size-mobile {
    padding: 0 16px 16px;
  }
}

.button {
  @include p-size-mobile {
    min-width: 100%;
  }
}

.input {
  @include p-size-mobile {
    max-width: 100%;
  }
}
