.icon {
  text-align: center;
}

.title {
  margin-top: 16px;
  text-align: center;
}

.subtitle {
  margin-top: 16px;
  text-align: center;
}

.button {
  display: inline-block !important;
  margin: 16px auto 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
